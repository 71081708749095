body {
  background: url('./images/bg-section-black.png') repeat;
  font-family: "Roboto Mono", sans-serif;
  color: #ffffff;
}

#app {
  width: 100%;
  height: 100%;
}

/* fonts */
.text-gems {
  color: #26FFBB; 
}

.gems-link a {
  color: #26FFBB;
  text-decoration: none;
}

.gems-link a:hover, .gems-link a:active {
  color: #C7C7C7;
  text-decoration: none;
}

.footer {
  color: #C7C7C7;
}

.footer a {
  text-decoration: none;
  display: inline;
  margin-left: 10px;
  color: #C7C7C7;
}

.footer a:hover, .footer a:active {
  color: #26FFBB;
}
/* elements */
ul.gems-info-list {
  margin: 0;
  padding: 0;
}

ul.gems-info-list li {
  background: url('./images/list-icon.png') no-repeat 0px 7px;
  padding: 0 0 20px 50px;
  /* reset styles (optional): */
  list-style: none;
  margin: 0;
  vertical-align: middle;
}

/* utilities */
.no-border {
  border: 0;
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: #26FFBB;
    border-color: #26FFBB;
    color: #000000;
    width: 146px;
}

.btn-primary:hover  {
    background-color: #2F5244;
    border-color: #26FFBB;
    color: #26FFBB;
    width: 146px;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
    background-color: #1B4840;
    /* background: url('./images/btn-secondary-bg.png') repeat; */
    border-color: #26FFBB;
    color: #26FFBB;
    width: 146px;
}

.btn-secondary:hover  {
    background-color: #26FFBB;
    border-color: #26FFBB;
    color: #000000;
    width: 146px;
}

/* Pages */
.phase1 {
  background: url('./images/main-bg.svg') no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.phase2 {
  width: 100%;
  height: 100%;
}

.phase2 > .section1 {
  background: url('./images/bg-section-1.png') no-repeat;
  background-size: cover;
}

.phase2 > .section2 {
  background: url('./images/bg-section-green.png') repeat;
  background-size: cover;
  border-top: 1px solid #206C57;
  border-bottom: 1px solid #206C57;
}

.phase2 > .section3 {
  background: url('./images/bg-section-3.png') no-repeat;
  background-size: cover;
}

.phase2 > .section4 {
  background: url('./images/bg-section-green.png') repeat;
  background-size: cover;
  border-top: 1px solid #206c57;
  border-bottom: 1px solid #206C57;
}

.phase2 > .section5 {
  background: url('./images/bg-section-5.png') no-repeat;
  background-size: cover;
}

.phase2 > .section6 {
  background: url('./images/bg-section-green.png') repeat;
  background-size: cover;
  border-top: 1px solid #206C57;
}

.phase2-video {
}

.video-player-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-player-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.phase3 {
  width: 100%;
  height: 100%;
}

.sdk-wrapper {
  width: 617px;
  height: 550px;
  border-color: #26FFBB;
  border-width: 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sdk {
  width: 100%;
  height: 100%;
}

.sale-end-text {
  font-size: 32px;
  color: #28FFBB;
  font-weight: bold;
  margin-bottom: 0px;
}

.sale-end-date {
  font-size: 24px;
  font-weight: 500;
}

/* Home */

/* container */

/**
 *
 * Small devices (landscape phones, 576px and up)
 */
@media (min-width: 576px) {
  .navbar-nav > li{
    padding-left:10px;
    padding-right:10px;
  }
}

/**
 *
 * Medium devices (tablets, 768px and up)
 */
@media (min-width: 768px) {
}

/**
 *
 * Large devices (desktops, 992px and up)
 */
@media (min-width: 992px) {
}

/**
 *
 * Extra large devices (large desktops, 1200px and up)
 */
@media (min-width: 1200px) {
}

/* TARGET SPECIFIC SCREEN SIZES */

/**
 * Small devices (landscape phones, 576px and up)
 */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/**
 * Medium devices (tablets, 768px and up)
 */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/**
 * Large devices (desktops, 992px and up)
 */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/**
  *  Extra small devices (portrait phones, less than 576px)
  */
@media (max-width: 575.98px) {
  .sdk-wrapper {
    position: relative;
    width: 100%;
    /*padding-top: 56.25%;  16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    border-color: #26FFBB;
    border-width: 2px;
  }
  
  .sdk {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

/**
  *  Small devices (landscape phones, less than 768px)
  */
@media (max-width: 767.98px) {
}

/**
  *  Medium devices (tablets, less than 992px)
  */
@media (max-width: 991.98px) {
}

/**
  *  Large devices (desktops, less than 1200px)
  */
@media (max-width: 1199.98px) {

}
